const jobs = [
	{ "id": 1, "type": "Job", "date": "2021 - ", "role": "Frontend Web Developer", "company": "NIVE (National Vocational and Adult Training Office)", "description": "Maintenance of the Office's websites and development of new websites and webapps - leave register, vehicle register." },
	{ "id": 2, "type": "Job", "date": "2017 - 2021", "role": "Freelance Web Developer", "company": "AR Digital", "description": "Website and webshop development for SMEs and start-ups, small graphic design projects." },
	{ "id": 3, "type": "Job", "date": "2019 - 2020", "role": "Startup Project Coordinator", "company": "Playsic - The Social Music App", "description": "Financial and administrative management of the business, planning; marketing and market entry processes." },
	{ "id": 5, "type": "Job", "date": "2016 - 2017", "role": "Web Builder", "company": "Kompel Kft.", "description": "Maintaining the company's web stores and creating new ones, producing social media content, helpdesk tasks." },
	{ "id": 6, "type": "Job", "date": "2015 - 2016", "role": "Campaign Manager", "company": "BeBrand Marketing Agency", "description": "Full planning of influencer campaigns, liaising with influencers and brands, graphic design and web design." },
	{ "id": 7, "type": "Job", "date": "2013 - 2015", "role": "Digital Project Manager & Site Builder", "company": "Demera Kft.", "description": "Management and implementation of web and identity projects, creation of websites and webshops." },
	{ "id": 8, "type": "School", "date": "2020 - 2021", "role": "Junior Frontend Developer", "company": "CodeCool", "description": "Frontend development basics: HTML, CSS, SCSS, JavaScript, React, SVG, project planning." },
	{ "id": 9, "type": "School", "date": "2012 - 2016", "role": "Economist in E-business specialization", "company": "Budapest Metropolitan University (METU)", "description": "Knowledge of the economics of e-commerce, proper web ergonomics, marketing and market entry skills." }
]

export default jobs;