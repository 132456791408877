const references = [
	{"id": 1, "name": "Choose Your Dog App", "platform": "React", "image": "https://ardigital.hu/wp-content/uploads/2021/08/choose-your-dog.png", "url": "https://dogapp.aranyrichard.hu", "description": "Filtering options to help you choose the right dogs for you."},
	{"id": 2, "name": "LikeSpace Office Rental Startup Platform","platform": "Wordpress", "image": "https://ardigital.hu/wp-content/uploads/2021/08/likespace.png", "url": "https://likespace.eu", "description": "A startup's web page that lets you book an office anywhere in the country for up to an hour."},
	{"id": 3, "name": "React and Morty App","platform": "React", "image": "https://ardigital.hu/wp-content/uploads/2021/08/react-and-morty.png", "url": "https://reactandmorty.aranyrichard.hu", "description": "Rick and Morty web app to learn about the characters and locations in the series."},
	{"id": 4, "name": "Weather App","platform": "React", "image": "https://ardigital.hu/wp-content/uploads/2021/08/weather.png", "url": "https://weather.aranyrichard.hu", "description": "Weather web app that shows the current and forecast weather in the selected city via an API key."},
	{"id": 5, "name": "Social Media Dashboard Frontend","platform": "React", "image": "https://ardigital.hu/wp-content/uploads/2021/08/social-media-dashboard.png", "url": "https://socialmedia.aranyrichard.hu", "description": "An interface for displaying social media data, which displays formatted data from an external file."},
	{"id": 6, "name": "Szikra CoWorking Website","platform": "Wordpress", "image": "https://ardigital.hu/wp-content/uploads/2021/08/szikra.png", "url": "https://szikra.space", "description": "Introduction page and booking system for a CoWorking space in downtown Budapest."},
	{"id": 7, "name": "ToDo App Frontend","platform": "React", "image": "https://ardigital.hu/wp-content/uploads/2021/08/todo.png", "url": "https://todo.aranyrichard.hu", "description": "ToDo app frontend interface to save your tasks and notes."},
	{"id": 8, "name": "Szépségterasz Beauty Salon Website","platform": "Wordpress", "image": "https://ardigital.hu/wp-content/uploads/2021/08/szepsegterasz.png", "url": "https://szepsegterasz.hu", "description": "A beauty salon's introductory website, information about their services and staff."},
	{"id": 9, "name": "Istvan Nyari Artist Portfolio Site","platform": "Wordpress", "image": "https://ardigital.hu/wp-content/uploads/2021/08/istvan-nyari.png", "url": "https://istvannyari.com", "description": "A painter's website with a gallery of his works."}
]

export default references;