import React from "react";

function Job(props) {
  const { job } = props;

  return (
      <div className={`${job.type}-job`}>
        <p className="date-text"><em>{job.type}</em></p>
        <p className="role-text">{job.role}</p>
        <p className="date-text">{job.date}</p>
        <p className="company-text">
          <strong>{job.company}</strong>
        </p>
        <p className="job-desc">
          {job.description}
        </p>
      </div>
  );
}

export default Job;
