import React from "react";
import jobs from "../../jobs";
import Job from "./Job";
import { useState } from 'react'
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

function Experience() {


  const [filtered, setFiltered] = useState("All")

  let filteredJobs = jobs

  if (filtered !== "All") {
    filteredJobs = filteredJobs.filter((job) => {
      return job.type === filtered
    })
  }


  return (
    <div>
      <div className="experience-inner-container">
        <div className="experience-inner-left-container">
          <h4 className="experience-h4-text">Experiences</h4>
          <Carousel plugins={['arrows', 'infinite']}>
            {filteredJobs.map((job) => (
              <Job key={job.id} job={job} />
            ))}
          </Carousel>

        </div>
      </div>
    </div>
  );
}

export default Experience;
